<template>
  <header
    class="hdr-m d-flex align-center"
    :class="{ 'light': headerLight, 'dark': !headerLight, 'hdr-m__black': $route.name === 'speedtest' }"
  >
    <div
      id="hdr-m__marker"
      class="hdr-m__marker"
    />
    <div class="container d-flex align-center">
      <component
        :is="$route.name === 'index' ? 'div' : 'a'"
        :href="$route.name === 'index' ? undefined : '/'"
        class="logo"
        @click.prevent="$router.push('/')"
      >
        <img
          v-if="headerLight"
          loading="lazy"
          src="~/assets/images/logo.svg"
          alt="logo"
        >
        <img
          v-else
          loading="lazy"
          src="~/assets/images/logo-white.svg"
          alt="logo"
        >
        {{
          houseUrl
            ? "ДомИнтернет"
            : `Домашний интернет ${getAddressText.cityOnlyLoc}`
        }}
      </component>
      <div class="hdr-m__actions d-flex align-center">
        <button
          v-if="!houseUrl"
          class="hdr-m__location"
          @click="showDialogAddress"
        />
        <button
          v-else
          class="hdr-m__address"
          @click="$router.push(`/personal/address/${houseUrl}/`)"
        >
          <span
            v-if="providersCount && $route.name !== 'personal-address-slug'"
            class="hdr-m__address-providers"
          >{{ providersCount }}</span>
          <span class="hdr-m__address-img d-flex" />
        </button>
        <button
          v-if="compareListTariffs.length"
          class="hdr-m__compare"
          @click="$router.push('/compare/')"
        >
          <span class="hdr-m__compare-img d-flex" />
          <span
            class="hdr-m__compare-count"
          >{{ compareListTariffs.length }}</span>
        </button>
        <button
          v-if="hasOrder"
          class="hdr-m__order d-flex align-center"
          @click="$router.push(`/personal/order/`)"
        >
          <span
            class="hdr-m__order-img d-flex"
          />
        </button>
        <a
          v-else-if="!segmentationActive && !hidePhoneNumber && !hideNumber"
          :href="callNumber.call_number ? 'tel:' + callNumber.call_number : undefined"
          class="hdr-m__phone d-flex"
          @click.prevent="getPhoneNumber"
        />
        <button
          aria-label="mobile menu"
          class="hdr-m__burger"
          @click="openMenu"
        >
          <span />
        </button>
      </div>
    </div>
    <ClientOnly>
      <VAppBar
        v-if="$route.name !== 'compare' && $route.name !== 'locations' && mounted"
        order="1"
        tag="div"
        scroll-behavior="hide"
        class="hdr-m__fixed hdr-m light"
        :class="{ 'hdr-m__fixed-hidden': hideAppBar }"
      >
        <div class="hdr-m__fixed-wrap container d-flex align-center">
          <component
            :is="$route.name === 'index' ? 'div' : 'a'"
            :href="$route.name === 'index' ? undefined : '/'"
            class="logo"
            @click.prevent="$router.push('/')"
          >
            <img
              loading="lazy"
              src="~/assets/images/logo.svg"
              alt="logo"
            >
            {{
              houseUrl
                ? "ДомИнтернет"
                : `Домашний интернет ${getAddressText.cityOnlyLoc}`
            }}
          </component>
          <button
            v-if="!houseUrl"
            class="hdr-m__fixed-loc d-flex align-center title-5"
            @click="showDialogAddress"
          >
            <span class="relative hdr-m__fixed-loc-pin d-flex" />
            Укажите адрес
          </button>
          <div class="hdr-m__actions d-flex align-center">
            <template
              v-if="houseUrl"
            >
              <button
                class="hdr-m__address"
              >
                <span
                  v-if="providersCount && $route.name !== 'personal-address-slug'"
                  class="hdr-m__address-providers"
                  @click="$router.push(`/personal/address/${houseUrl}/`)"
                >
                  {{ providersCount }}</span>
                <span class="hdr-m__address-img d-flex" />
              </button>
              <button
                v-if="compareListTariffs.length"
                class="hdr-m__compare"
                @click="$router.push('/compare/')"
              >
                <span class="hdr-m__compare-img d-flex" />
                <span
                  class="hdr-m__compare-count"
                >{{ compareListTariffs.length }}</span>
              </button>
              <button
                v-if="hasOrder"
                class="hdr-m__order d-flex align-center"
                @click="$router.push(`/personal/order/`)"
              >
                <span
                  class="hdr-m__order-img d-flex"
                />
              </button>
              <a
                v-else-if="!segmentationActive && !hidePhoneNumber && !hideNumber"
                :href="callNumber.call_number ? 'tel:' + callNumber.call_number : undefined"
                class="hdr-m__phone d-flex"
                @click.prevent="getPhoneNumber"
              />
            </template>
            <button
              aria-label="mobile menu"
              class="hdr-m__burger"
              @click="openMenu"
            >
              <span />
            </button>
          </div>
        </div>
      </VAppBar>
    </ClientOnly>
    <LazyNavigateMobileNavbar
      v-if="!needGetCity"
      v-model="menuOpenned"
      :providers-count="providersCount"
      :available-houses="availableHouses"
      @close="closeMenu"
    />
  </header>
</template>

<script setup lang="ts">
import { VAppBar } from 'vuetify/lib/components/VAppBar'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { providersComposible } from '~/composible/getProviders'
import { setCompare } from '~/composible/setFavourites'
import { getCallNumber } from '~/composible/getCallNumber'

const ctx = useNuxtApp()
const $route = useRoute()
const cityStore = useCities()
const mainStore = useMainStore()
const tariffsStore = useTariffs()
const getAddressText = computed(() => tariffsStore.getAddressText)
const houseUrl = computed(() => tariffsStore.getHouseUrl)
const callNumber = computed(() => mainStore.getCallNumber?.connect)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const hideNumber = computed(() => mainStore.getHideNumber)
const hidePhoneNumber = computed(() => $route.name === 'speedtest' || $route.name?.toString()?.startsWith('downdetector'))
const needGetCity = computed(() => cityStore.getNeedGetCity)
const currentCity = computed(() => cityStore.getCity)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const userInfo = computed(() => mainStore.getUserAuth)
const hasOrder = computed(() => mainStore.getUserAuth?.interactionOrdersCount)
const { compareListTariffs } = setCompare()
const { getPhoneNumber } = getCallNumber()
const menuOpenned = ref(false)
const hideAppBar = ref(false)
const mounted = ref(false)

const client = ctx._apolloClients.default
const { fetchProviders } = providersComposible()
const sessionid = useCookie('sessionid')

const headerLight = computed(() => {
  return (
    $route.name !== 'city-url'
    && $route.name !== 'city-for-house'
    && $route.name !== 'city-streets-street'
    && $route.name !== 'city-providers-to-address'
    && $route.name !== 'providers-to-address'
    && $route.name !== 'index'
    && $route.name !== 'speedtest'
  )
})
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'header' })
}
const closeMenu = () => {
  menuOpenned.value = false
  if (window && !import.meta.server)
    window.document.getElementsByTagName('html')[0].classList.remove('hidden')
}
const openMenu = () => {
  menuOpenned.value = true
  if (window && !import.meta.server) {
    window.document.getElementsByTagName('html')[0]?.classList.add('hidden')
  }
}
const options = {
  rootMargin: '0px',
  threshold: 1,
}
const checkPosition = (entries) => {
  if (
    entries
  ) {
    mounted.value = true
    hideAppBar.value = entries[0].isIntersecting
  }
}
const availableHouses = computed(() => data.value?.houseTypes?.includes('HOUSE'))
const providersCount = computed(() => data.value?.providers?.length)

const fetchHouseTypes = async () => {
  if (currentCity.value?.fias_id) {
    const res = await client.query({
      query: gql`
        query getHouseTypes($input: LocationInfoInput!) {
          location {
            locationTerms(input: $input) {
              tariffsAgg {
                availableHouseTypes
              }
            }
          }
        }
      `,
      variables: {
        input: {
          addrObjFiasId: currentCity.value.fias_id,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })

    return res.data.location.locationTerms.tariffsAgg?.availableHouseTypes
  }
}

let errorStatus
let errorMessage
const { data, error, refresh } = await useAsyncData('header' + (currentCity.value?.fias_id || '') + (houseUrl.value || ''), async () => {
  let providers: any
  let houseTypes: any

  const providersHouseFetch = async () => {
    providers = await fetchProviders()
  }

  const houseTypesFetch = async () => {
    houseTypes = await fetchHouseTypes()
  }

  if (houseUrl.value)
    await Promise.all([providersHouseFetch(), houseTypesFetch()])
  else await Promise.all([houseTypesFetch()])

  return {
    providers,
    houseTypes,
  }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}
watch(
  () => houseUrl.value,
  async () => {
    if (houseUrl.value && data.value)
      data.value.providers = await fetchProviders()
    else if (data.value) data.value.providers = undefined
  },
)
const userInfoAsyncFetch = async () => {
  if (sessionid.value && !userInfo.value?.interactionOrdersCount) {
    const auth = (
      await client.query({
        query: gql`
          mutation Mutation {
            auth {
              currentUserInfo {
                lastName
                firstName
                siteUserId
                verified
                avatarUrl
                interactionOrdersCount
              }
            }
          }
        `,
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data

    mainStore.$patch({
      userAuth: auth.auth.currentUserInfo,
    })
    if (!auth.auth?.currentUserInfo?.interactionOrdersCount)
      setTimeout(() => userInfoAsyncFetch, 60000)
  }
}
onMounted(() => {
  userInfoAsyncFetch()
  const target = document.querySelector('#hdr-m__marker')!
  const observer = new IntersectionObserver(checkPosition, options)
  observer.observe(target)
})
</script>

<style scoped lang="scss">
.hdr-m {
  height: 64px;
  @media (max-width: getBreakpoint(mobile-lg)) {
    height: 54px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    height: 52px;
  }
  &.dark {
    background: color(gradient);
    color: color(white);
  }
  &.light {
    background: color(white);
    color: color(black);
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);
    position: relative;
    z-index: 103;
  }

  &__black {
    background: #141526 !important;
  }

  &__actions {
    margin-left: auto;
  }

  &__marker {
    position: absolute;
    top: 300px;
    width: 100%;
  }

  &__location {
    margin-right: 16px;
    width: 40px;
    height: 40px;
    mask-image: url('@/assets/images/svg/pin-thin.svg');
    background-color: currentColor;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 32px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      mask-size: 30px;
      width: 32px;
      height: 32px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      mask-size: 28px;
    }
  }
  &__address {
    position: relative;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 32px;
      height: 32px;
    }
    &-img {
      width: 40px;
      height: 40px;
      mask-image: url('@/assets/images/svg/house.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        mask-size: 30px;
        width: 32px;
        height: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        mask-size: 28px;
      }
    }
    &-providers {
      font-size: fontSize(small);
      line-height: 11px;
      background: color(primary);
      border: 1px solid color(white);
      color: color(white);
      position: absolute;
      left: 19px;
      top: 4px;
      padding: 2px 6px 2px;
      border-radius: 12px;
      z-index: 1;
      @media (max-width: getBreakpoint(mobile-lg)) {
        left: 15px;
        top: 0;
      }
    }
  }
  &__order {
    position: relative;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 32px;
      height: 32px;
    }
    &-img {
      width: 40px;
      height: 40px;
      mask-image: url('@/assets/images/svg/user.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        mask-size: 30px;
        width: 32px;
        height: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        mask-size: 28px;
      }
    }
    &:after {
      content: "";
      background: url("@/assets/images/useful/ischeckedWhite.svg") 50% 50%
        no-repeat;
      background-size: contain;
      width: 12px;
      height: 12px;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: color(red);
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &:before, &:after {
      top: 28px;
      left: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        top: 23px;
        left: 23px;
      }
    }
  }
  &__compare {
    position: relative;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 32px;
      height: 32px;
    }
    &-img {
      width: 40px;
      height: 40px;
      mask-image: url('@/assets/images/svg/compare-menu.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 32px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        mask-size: 30px;
        width: 32px;
        height: 32px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        mask-size: 28px;
      }
    }
    &-count {
      font-size: fontSize(small);
      line-height: 11px;
      background: color(primary);
      border: 1px solid color(white);
      color: color(white);
      position: absolute;
      left: 19px;
      top: 4px;
      padding:2px 6px 2px;
      border-radius: 12px;
      z-index: 1;
      @media (max-width: getBreakpoint(mobile-lg)) {
        left: 15px;
        top: 0;
      }
    }
  }

  &__phone {
    color: currentColor;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    mask-image: url('@/assets/images/svg/phone-thin.svg');
    background-color: currentColor;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 32px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      mask-size: 30px;
      width: 32px;
      height: 32px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      mask-size: 28px;
    }
  }
  &__burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    @media (max-width: getBreakpoint(tablet)) {
      width: 32px;
      height: 32px;
    }
    span {
      display: block;
      height: 2px;
      background: currentColor;
      transition: background 0.24s;
      width: 30px;
      position: relative;
      @media (max-width: getBreakpoint(tablet)) {
        width: 22px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 20px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 18px;
      }

      &:before,
      &:after {
        content: "";
        left: 0;
        right: 0;
        height: 2px;
        background: currentColor;
        position: absolute;
        transition: 0.24s;
        transition-property: transform, top, bottom, background;
      }

      &:before {
        top: -9px;
        @media (max-width: getBreakpoint(tablet)) {
          top: -7px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          top: -6px;
        }
      }

      &:after {
        bottom: -9px;
        @media (max-width: getBreakpoint(tablet)) {
          bottom: -7px;
        }
        @media (max-width: getBreakpoint(mobile-md)) {
          bottom: -6px;
        }
      }
    }
  }

  &__fixed {
    box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.15) !important;
    @media (max-width: getBreakpoint(tablet)) {
      height: 48px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 46px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 44px;
    }

    &:deep(.v-toolbar__content) {
      height: 100% !important;
    }

    &-hidden {
      transform: translateY(-64px) !important;
    }

    &-loc {
      margin-left: 12px;
      background-color: color(primary);
      border: 1px solid transparent;
      color: color(white);
      padding: 4px 12px;
      border-radius: 28px;
      height: 32px;
      @media (max-width: getBreakpoint(mobile-md)) {
        padding: 4px 8px;
        margin-left: 8px;
      }
      &-pin {
        margin-right: 4px;
        width: 24px;
        height: 24px;
        margin-top: -4px;
        margin-bottom: -4px;
        mask-image: url('@/assets/images/svg/location.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 24px;
      }
    }
  }
}
</style>
